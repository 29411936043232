import { render, staticRenderFns } from "./PopupGallery.vue?vue&type=template&id=467f6f9a&scoped=true&"
import script from "./PopupGallery.vue?vue&type=script&lang=js&"
export * from "./PopupGallery.vue?vue&type=script&lang=js&"
import style0 from "./PopupGallery.vue?vue&type=style&index=0&id=467f6f9a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "467f6f9a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
installComponents(component, {VCarousel,VCarouselItem})
