const getters = {};
const mutations = {};

const actions = {};
const state = {};

export default {
  state,
  getters,
  actions,
  mutations
};
