let getCampSiteIndex = (campsitesOrderIndexArray, orderIndex) => {
  let index = campsitesOrderIndexArray.indexOf(String(orderIndex));

  return index === -1 ? false : index;
};

let getBothIndexes = state => {
  let fromOrderIndex = getCampSiteIndex(
    state.campsitesOrderIndexArray,
    state.fromCampOrderIndex
  );
  let toOrderIndex = getCampSiteIndex(
    state.campsitesOrderIndexArray,
    state.toCampOrderIndex
  );

  let valid = true;
  let bothValid = true;
  let bothSame = false;
  let correctOrder = true;

  if (fromOrderIndex === false || toOrderIndex === false) {
    bothValid = false;
    valid = false;
  }

  if (bothValid && fromOrderIndex >= toOrderIndex) {
    correctOrder = false;
    valid = false;
  }

  if (bothValid && fromOrderIndex == toOrderIndex) {
    bothSame = false;
    valid = false;
  }
  return {
    fromOrderIndex,
    toOrderIndex,
    bothValid,
    correctOrder,
    valid,
    bothSame,
    fromIsMin: fromOrderIndex === 0,
    fromIsMax: fromOrderIndex === state.campsitesOrderIndexArray.length - 1,
    toIsMin: toOrderIndex === 0,
    toIsMax: toOrderIndex === state.campsitesOrderIndexArray.length - 1
  };
};

const validateToFrom = (state, type, goes) => {
  let values = getBothIndexes(state);

  if (goes > 10) {
    console.error("is broken due to goes lmit");
    return false;
  }

  if (values?.valid === true) {
    //console.log("is valid", goes);
    return true;
  } else if (values?.bothValid === false) {
    //console.log("both not valid", goes);

    if (values.fromOrderIndex === false) {
      state.fromCampOrderIndex = state.minCampOrderIndexVal;
    }
    if (values.fromOrderIndex === false) {
      state.toCampOrderIndex = state.maxCampOrderIndexVal;
    }
    goes++;
    return validateToFrom(state, type, goes);
  } else if (!values?.bothSame === true || values.correctOrder === false) {
    //console.log("same or bad order", goes);

    if (type === "from") {
      if (values.fromIsMax) {
        state.fromCampOrderIndex =
          state.campsitesConfig[state.fromCampOrderIndex - 1].orderIndex;
      } else {
        state.toCampOrderIndex =
          state.campsitesConfig[state.fromCampOrderIndex + 1].orderIndex;
      }
    } else {
      if (values.toIsMin) {
        state.toCampOrderIndex =
          state.campsitesConfig[state.toCampOrderIndex + 1].orderIndex;
      } else {
        state.fromCampOrderIndex =
          state.campsitesConfig[state.toCampOrderIndex - 1].orderIndex;
      }
    }
    goes++;
    return validateToFrom(state, type, goes);
  }
};

module.exports.validateToFrom = validateToFrom;
