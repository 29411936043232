<template>
  <div>
    <v-card-title v-if="layerId" class="pt-1 mb-1 pb-1">
      POI Layer: {{ layerId }}
    </v-card-title>

    <v-card-subtitle v-if="properties.icon">
      Icon: {{ properties.icon }}
    </v-card-subtitle>

    <v-card-text class="pt-0 pb-1">
      <vue-json-pretty :data="properties" />
    </v-card-text>

    <mediaButtons
      :acfMedia="example_acfMedia"
      :media_urls="example_mediaUrls"
      :properties="properties"
    ></mediaButtons>
  </div>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";
import mediaButtons from "./../../../media/mediaButtons";

export default {
  name: "nonCampsitePopup",
  components: { VueJsonPretty, mediaButtons },

  data: () => ({
    example_mediaUrls: [
      {
        id: 532,
        title: "IMG_4249",
        slug: "img_4249",
        caption: "",
        description: "",
        src:
          "http://omwptest.onemap.com.au/wp-content/uploads/2024/10/IMG_4249.jpg",
        small_marker:
          "https://omwptest.onemap.com.au/wp-content/uploads/2024/10/IMG_4249-200x150.jpg",
        popup_thumb:
          "https://omwptest.onemap.com.au/wp-content/uploads/2024/10/IMG_4249-450x420.jpg",
        gallery_wide:
          "https://omwptest.onemap.com.au/wp-content/uploads/2024/10/IMG_4249-1440x1080.jpg",
        gallery_smallwide:
          "https://omwptest.onemap.com.au/wp-content/uploads/2024/10/IMG_4249-960x720.jpg",
        post_mime_type: "image/jpeg",
        size: {
          small_marker: [
            "https://omwptest.onemap.com.au/wp-content/uploads/2024/10/IMG_4249-200x150.jpg",
            200,
            150,
            true
          ],
          popup_thumb: [
            "https://omwptest.onemap.com.au/wp-content/uploads/2024/10/IMG_4249-450x420.jpg",
            450,
            420,
            true
          ],
          gallery_wide: [
            "https://omwptest.onemap.com.au/wp-content/uploads/2024/10/IMG_4249-1440x1080.jpg",
            1440,
            1080,
            true
          ],
          gallery_smallwide: [
            "https://omwptest.onemap.com.au/wp-content/uploads/2024/10/IMG_4249-960x720.jpg",
            960,
            720,
            true
          ]
        }
      },
      {
        id: 530,
        title: "IMG_4245",
        slug: "img_4245",
        caption: "",
        description: "",
        src:
          "http://omwptest.onemap.com.au/wp-content/uploads/2024/10/IMG_4245.jpg",
        small_marker:
          "https://omwptest.onemap.com.au/wp-content/uploads/2024/10/IMG_4245-200x150.jpg",
        popup_thumb:
          "https://omwptest.onemap.com.au/wp-content/uploads/2024/10/IMG_4245-450x420.jpg",
        gallery_wide:
          "https://omwptest.onemap.com.au/wp-content/uploads/2024/10/IMG_4245-1440x1080.jpg",
        gallery_smallwide:
          "https://omwptest.onemap.com.au/wp-content/uploads/2024/10/IMG_4245-960x720.jpg",
        post_mime_type: "image/jpeg",
        size: {
          small_marker: [
            "https://omwptest.onemap.com.au/wp-content/uploads/2024/10/IMG_4245-200x150.jpg",
            200,
            150,
            true
          ],
          popup_thumb: [
            "https://omwptest.onemap.com.au/wp-content/uploads/2024/10/IMG_4245-450x420.jpg",
            450,
            420,
            true
          ],
          gallery_wide: [
            "https://omwptest.onemap.com.au/wp-content/uploads/2024/10/IMG_4245-1440x1080.jpg",
            1440,
            1080,
            true
          ],
          gallery_smallwide: [
            "https://omwptest.onemap.com.au/wp-content/uploads/2024/10/IMG_4245-960x720.jpg",
            960,
            720,
            true
          ]
        }
      }
    ],
    example_acfMedia: {
      hh_media_gsp_google_photosphere_01: {
        label: "Google Photosphere 01",
        value: {
          title: "",
          url:
            "https://www.google.com/maps/embed?pb=!4v1728105258550!6m8!1m7!1sF%3A-7DhQsBOxWME%2FViYQnbPVAKI%2FAAAAAAAAP_E%2FyHsV47t6x9IkocsnqVeMh0uHXtH4MnaRQCLIB!2m2!1d-35.17078770208362!2d148.6022044154981!3f89.71!4f12.510000000000005!5f0.7820865974627469",
          target: ""
        },
        type: "Photosphere"
      },
      hh_media_link_sitelink_01: {
        label: "Link 01",
        value: {
          title: "Link Example 1",
          url: "https://www.google.com",
          target: ""
        },
        type: "weblink"
      },
      hh_media_vl_video_link_01: {
        label: "Video 01",
        value: {
          title: "Portable Espresso",
          url: "https://www.youtube.com/watch?v=ig_vJqAjyfU",
          target: ""
        },
        type: "videoLink"
      }
    }
  }),

  props: {
    layerId: String,
    properties: Object,
    functions: Object
  },

  computed: {}
};
</script>
<style scoped></style>
