export class EmptyTargetControl {
  constructor(options) {
    this.checkboxValues = {};
    this.container = document.createElement("div");
    this.container.className = `mapboxgl-ctrl mapboxgl-ctrl-group emptyTargetMapItem ${options.extraClass}`;
    this.controlItem = document.createElement("div");
    this.controlItem.setAttribute("id", options.id);
    this.container.appendChild(this.controlItem);
  }

  onAdd(map) {
    this.map = map;
    return this.container;
  }
  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}
export default {
  EmptyTargetControl
};
