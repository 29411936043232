<template>
  <div class="d-flex flex-row legStats sublegblock">
    <div class="pr-2" v-if="day">
      <font class="prop">Day: </font>
      <font class="day">{{ day }} </font>
    </div>
    <div class="pr-2" v-if="iteminfo.distance">
      <DistTimeDiffBlock
        title="Distance"
        displaytype="sidelist"
        :stats="iteminfo"
      ></DistTimeDiffBlock>
    </div>
  </div>
</template>

<script>
import DistTimeDiffBlock from "./../infoblocks/DistTimeDiffBlock.vue";

export default {
  name: "SideBarLegStats",

  components: { DistTimeDiffBlock },

  data: () => ({}),
  props: {
    iteminfo: Object,
    compact: Boolean,
    day: Number
  },
  methods: {}
};
</script>

<style>
.legStats,
.sublegblock {
  color: black;
}
.legStats .prop {
  font-weight: bold;
}

.legStats .rise {
  color: red;
}

.legStats .descent {
  color: blue;
}
</style>
