const valueHack = (acfKey, value) => {
  try {
    if (acfKey == "hh_am_multiselect_water") {
      if (value.includes("No")) {
        value = ["No"];
      }

      if (value.length > 2) {
        value.length = 2;
      }
    }
  } catch (error) {
    //Do nothing
  }
  return value;
};

const getType = acfKey => {
  let type = "";
  if (acfKey.includes("hh_media_vl_video")) {
    type = "videoLink";
  } else if (acfKey.includes("hh_media_link_")) {
    type = "weblink";
  } else if (acfKey.includes("hh_media_gsp_")) {
    type = "Photosphere";
  } else if (acfKey.includes("hh_text_campsite_id")) {
    type = "campsite_id";
  } else if (acfKey.includes("hh_osmlonglat_location")) {
    type = "osmlonglat_location";
  } else if (acfKey.includes("_multiselect_")) {
    type = "multiselect";
  } else if (acfKey.includes("_textarea_")) {
    type = "textarea";
  } else if (acfKey.includes("_bool_")) {
    type = "bool";
  } else if (acfKey.includes("_link_")) {
    type = "weblink";
  } else if (acfKey.includes("_numb_time_")) {
    type = "numb_time";
  } else if (acfKey.includes("_select_")) {
    type = "select";
  } else if (acfKey.includes("_yesno_")) {
    type = "yesno";
  } else if (acfKey.includes("_checkbox_")) {
    type = "checkbox";
  } else if (acfKey.includes("_text_")) {
    type = "text";
  } else {
    type = "";
  }
  return type;
};
const splitOutAcfData = acf => {
  let campSiteID = null;
  let acfAmenities = {};
  let acfTrack = {};
  let acfCampsite = {};

  let acfMedia = {};
  let acfOther = {};

  let skip = {
    hh_osmlonglat_location: true
  };

  for (const [acfKey, data] of Object.entries(acf)) {
    try {
      let { /* label,*/ value } = data;

      value = valueHack(acfKey, value);
      data.value = value;
      if (
        !skip[acfKey] &&
        (value || value === 0 || value === "0" || value === false)
      ) {
        let type = getType(acfKey);

        if (acfKey === "hh_text_campsite_id") {
          campSiteID = value;
        } else if (acfKey.includes("hh_am_")) {
          acfAmenities[acfKey] = { ...data, type };
        } else if (acfKey.includes("hh_media_")) {
          acfMedia[acfKey] = { ...data, type };
        } else if (acfKey.includes("hh_track_")) {
          acfTrack[acfKey] = { ...data, type };
        } else if (acfKey.includes("hh_camp_")) {
          acfCampsite[acfKey] = { ...data, type };
        } else {
          acfOther[acfKey] = { ...data, type };
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  let output = {
    campSiteID,
    acfAmenities,
    acfTrack,
    acfCampsite,
    acfMedia,
    acfOther
  };

  if (acfCampsite?.hh_camp_bool_campsite_closed?.value === true) {
    output.campsite_closed = true;
  }

  if (acfCampsite?.hh_camp_bool_skip_campsite?.value === true) {
    output.campsite_defaultskip = true;
  }

  if (output.campSiteID) {
    output.id = output.campSiteID;
  }

  if (!campSiteID) {
    delete output.campSiteID;
  }

  try {
    if (Object.values(acfAmenities).length == 0) {
      delete output.acfAmenities;
    }
  } catch (error) {
    //do nothing;
  }

  try {
    if (Object.values(acfTrack).length == 0) {
      delete output.acfTrack;
    }
  } catch (error) {
    //do nothing;
  }

  try {
    if (Object.values(acfCampsite).length == 0) {
      delete output.acfCampsite;
    }
  } catch (error) {
    //do nothing;
  }

  try {
    if (Object.values(acfMedia).length == 0) {
      delete output.acfMedia;
    }
  } catch (error) {
    //do nothing;
  }

  try {
    if (Object.values(acfOther).length == 0) {
      delete output.acfOther;
    }
  } catch (error) {
    //do nothing;
  }

  return output;
};

module.exports.split = splitOutAcfData;
