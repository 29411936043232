var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mapWrapper",class:{
    drawerOpen: _vm.drawer
  }},[_c('mapbox',{attrs:{"access-token":_vm.appSettings.map.accessToken,"map-options":Object.assign({}, _vm.options, _vm.appSettings.map.options),"nav-control":{
      show: false,
      position: 'top-left'
    },"scale-control":{
      show: false,
      position: 'top-left'
    },"fullscreen-control":{
      show: false,
      position: 'top-left'
    }},on:{"map-load":_vm.loaded,"map-init":_vm.initialized}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }