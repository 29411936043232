//import _, { initial } from "lodash";
import { mapState } from "vuex";
import { eventBus } from "./../../../../main";

import PopupContent from "./PopupContent.vue";
import AnimatedPopup from "mapbox-gl-animated-popup";

const _ = require("lodash");

export default {
  data: () => ({
    //layerState: false
    currentPopup: null,
    currentPopupId: null,
    hasInfoIdsArrayForPopup: ["filteredCampsitesGeoJson"],
    poiLayerIdForPopup: ["dummydata1", "dummydata2", "dummydata3", "dummydata4"]
  }),

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      filteredCampsitesGeoJson: state => state.trail.filteredCampsitesGeoJson
    }),
    skipCampById: {
      get() {
        return this.$store.state.trail.skipCampById;
      },
      set(value) {
        this.$store.dispatch("trail_update_skiplist_set", value);
      }
    }
  },

  methods: {
    handleSkipCheckboxChangePopup(id) {
      this.clearAllPopupsQuiet();
      let isChecked = !this.skipCampById.includes(id);
      if (isChecked) {
        // Add id to the array if checked
        this.skipCampById.push(id);
      } else {
        // Remove id from the array if unchecked
        const index = this.skipCampById.indexOf(id);
        if (index > -1) {
          this.skipCampById.splice(index, 1);
        }
      }
      this.$store.dispatch("trail_update_skiplist_set", this.skipCampById);

      setTimeout(() => {
        this.openCampSitePopupFromListById(id, true);
      }, 50);
    },

    clearAllPopupsQuiet() {
      try {
        if (this.currentPopup) {
          this.currentPopup.remove();
        }
      } catch (error) {
        //do nothing;
      }
    },
    clearAllPopups(where) {
      if (where) {
        //do nothing;
      }
      try {
        if (this.currentPopup) {
          this.currentPopup.remove();
        }
      } catch (error) {
        //do nothing;
      }
      this.currentPopup = null;

      this.$store.dispatch("navUpdate_infoWindowTarget", {});
    },
    openCampSitePopupFromListById(id, quiet) {
      this.clearAllPopups("AA");

      try {
        let point = this.filteredCampsitesGeoJson.features.filter(
          row => row.id === id
        )[0];

        let { properties, geometry } = point;

        let { coordinates } = geometry;

        if (!coordinates || !properties.id) {
          throw { error: true, message: "point not found" };
        } else {
          //openPoup,
          this.openCampSitePopup(coordinates, properties);

          //flyToPoint

          if (!quiet) {
            let options = {};
            if (this.appSettings.popupMinZoom > this.currentZoom) {
              options.zoom = this.appSettings.popupTargetZoom;
            }

            this.map.flyTo({ center: coordinates, ...options });
          }
        }
      } catch (error) {
        console.error({ error });
      }
    },

    openCampSitePopup_debounced: _.debounce(function(
      coordinates,
      properties,
      options
    ) {
      this.openCampSitePopup(coordinates, properties, options);
    },
    50),

    openCampSitePopup(coordinates, properties, options) {
      //let { isNotCampsite, layerId } = options;
      let layerId = "Campsite";
      let isCampsite = !options?.isNotCampsite ? true : false;
      if (options?.layerId) {
        layerId = options?.layerId;
      }

      this.clearAllPopups("Z");
      this.currentPopupId = properties.id;

      this.currentPopup = new AnimatedPopup({
        className: "galPopup hhPopupAll",
        closeButton: false,

        openingAnimation: {
          duration: 300,
          easing: "easeInOutExpo", //"easeOutExpo",//"easeOutElastic",
          transform: "scale"
        },
        closingAnimation: {
          duration: 300,
          easing: "easeInBack",
          transform: "scale"
        }
      })
        .setLngLat(coordinates)
        .setHTML('<div id="vue-popup-content"></div>')
        //.setOffset(this.getOffest())
        .addTo(this.map);

      try {
        let coordinatesObj = coordinates;
        if (
          coordinatesObj[0] &&
          coordinatesObj[1] &&
          !isNaN(coordinatesObj[1])
        ) {
          coordinatesObj = {
            lng: coordinatesObj[0],
            lat: coordinatesObj[1]
          };
        }

        this.$store.dispatch("navUpdate_infoWindowTarget", coordinatesObj);

        new PopupContent({
          propsData: {
            isCampsite,

            layerId,

            properties: properties,
            clearAllPopups: this.clearAllPopups,

            skipCampById: this.skipCampById,

            vuetifyCopy: this.$vuetify,
            browserDetectCopy: this.$browserDetect,
            appSettings: this.appSettings,
            functions: {
              handleSkipCheckboxChangePopup: this.handleSkipCheckboxChangePopup
            }
          }
        }).$mount("#vue-popup-content");
      } catch (error) {
        //do nothink;
      }
    },
    initializeInfoWindowEvents() {
      console.log("initializeInfoWindowEvents");

      let popupLayers = [
        ...this.hasInfoIdsArrayForPopup,
        ...this.poiLayerIdForPopup
      ];

      let allLayers = this.map.getStyle().layers.map(row => row.id);

      popupLayers = popupLayers.filter(row => allLayers.includes(row));

      popupLayers.forEach(layerId => {
        console.log("initializeInfoWindowEvents", layerId);

        var isPoiLayer = this.poiLayerIdForPopup.includes(layerId);
        if (isPoiLayer) {
          //do nothing
        }
        //is valid click;
        if (layerId && allLayers.includes(layerId)) {
          var campsiteArray = this.hasInfoIdsArrayForPopup;

          this.map.on("mouseenter", layerId, () => {
            this.map.getCanvas().style.cursor = "pointer";
          });

          // Change it back to a pointer when it leaves.
          this.map.on("mouseleave", layerId, () => {
            this.map.getCanvas().style.cursor = "";
          });

          this.map.on("click", layerId, e => {
            let feature = false;
            let isCampsite = false;

            this.clearAllPopups("A");
            let coordinates = e.lngLat;

            let features = this.map.queryRenderedFeatures(e.point, {
              layers: [...popupLayers]
            });

            let campsites = features.filter(row => {
              return campsiteArray.includes(row.source);
            });

            if (campsites[0]) {
              feature = campsites[0];
              isCampsite = true;
            } else if (features[0]) {
              feature = features[0];
            } else if (e.features[0]) {
              feature = e.features[0];

              if (features?.properties?.campSiteID) {
                isCampsite = true;
              }
            }

            //let [feature, otherFeatures] = e.features;

            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            let { properties, geometry } = feature;

            if (geometry?.type == "Point") {
              try {
                if (geometry?.coordinates?.slice()) {
                  coordinates = geometry?.coordinates?.slice();
                }
              } catch (error) {
                //do nothing;
              }
            }

            if (geometry.type != "Point") {
              coordinates = e.lngLat;
            }

            let isNotCampsite = !isCampsite;
            this.openCampSitePopup_debounced(coordinates, properties, {
              isNotCampsite,
              layerId
            });
          });
        }
      });
    }
  },
  created() {},
  destroyed() {},
  mounted() {
    eventBus.$on("map_openCampSitePopupById", id => {
      this.openCampSitePopupFromListById(id);
    });
    eventBus.$on("map_clearAllPopups", () => {
      this.clearAllPopupsQuiet();
    });
  },
  watch: {}
};
