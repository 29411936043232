//import _, { initial } from "lodash";

import { mapState } from "vuex";

export default {
  data: () => ({
    //layerState: false
    currentPopup: null
  }),

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      maplayerdata: state => state.global.maplayerdata,

      initalQueryStringObject: state => state.navigation.initalQueryStringObject
    })
  },

  methods: {
    getDecodedQueryString() {
      let temp = {
        zoom: 10.62,
        centerLat: -37.807239,
        centerLng: 144.937,
        themeIndex: 0,
        layerIndex: 0,
        infoWindowTargetLat: -37.758564,
        infoWindowTargetLng: 144.774856
      };
      if (temp) {
        //do nothing;
      }

      let hasValues = false;
      if (
        !isNaN(this?.initalQueryStringObject?.zoom) &&
        !isNaN(this?.initalQueryStringObject?.centerLat) &&
        !isNaN(this?.initalQueryStringObject?.centerLng) &&
        !isNaN(this?.initalQueryStringObject?.themeIndex) &&
        !isNaN(this?.initalQueryStringObject?.layerIndex)
      ) {
        hasValues = true;
      }

      if (!hasValues) {
        return null;
      } else {
        return this.initalQueryStringObject;
      }
    },

    startTrackingEvents() {
      let readyToUpdatePath = true;
      this.$store.dispatch("navUpdate_readyToUpdatePath", readyToUpdatePath);
    }
  },
  created() {},
  destroyed() {},
  mounted() {},
  watch: {}
};
