import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify, {
  iconfont: "md"
});
export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#592625",

        xprimary: "#cccccc",

        topMenu: "#495a58",
        controlbox: "#c0cfcd",

        accent: "#495a58",
        //secondary: "#2d3739",
        success: "#f2f2f2"
        //primary: "#018e42"
      }
    }
  }
});
