var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex  flex-row   flex-wrap compactList"},[_vm._l((_vm.acfAmenities),function(data,acfkey){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkShow(acfkey, data)),expression:"checkShow(acfkey, data)"}],key:("acfAmenities_" + acfkey)},[_c('acfValue',{attrs:{"acfkey":acfkey,"data":data,"properties":_vm.properties}})],1)]})],2),(_vm.whereShow === 'popup' && _vm.acfCampsite)?_c('div',[(_vm.acfCampsite.hh_camp_textarea_campsite_notes.value)?_c('div',{ref:"noteTextContent",class:{
        clampFourLines: !_vm.noteIsExpanded,
        campText: true,
        'mt-1': true
      }},[_vm._v(" "+_vm._s(_vm.acfCampsite.hh_camp_textarea_campsite_notes.value)+" ")]):_vm._e(),(
        _vm.acfCampsite.hh_camp_textarea_campsite_notes.value && _vm.isOverflowing
      )?_c('button',{on:{"click":_vm.toggleText}},[_c('b',[_vm._v(" "+_vm._s(_vm.noteIsExpanded ? "Show Less" : "Show More"))])]):_vm._e(),(
        _vm.acfCampsite.hh_camp_textarea_campsite_warning &&
          _vm.acfCampsite.hh_camp_textarea_campsite_warning.value
      )?_c('div',{staticClass:"mt-1 campText"},[_c('font',{directives:[{name:"show",rawName:"v-show",value:(_vm.showWarning),expression:"showWarning"}]},[_c('b',{staticStyle:{"color":"red"}},[_vm._v("Notices / Warning: ")]),_vm._v(" "+_vm._s(_vm.acfCampsite.hh_camp_textarea_campsite_warning.value)+" ")]),_c('button',{on:{"click":function($event){_vm.showWarning = !_vm.showWarning}}},[_c('b',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.showWarning ? "Hide " : "** Show Notices / Warning **"))])])],1):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }