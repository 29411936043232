<template>
  <div class="d-flex flex-row subLegStats sublegblock">
    <div class="pr-2 subheading">
      <b>SubLegs:</b>
    </div>

    <div class="pr-2">
      <div v-for="item in iteminfo" :key="item.title" class="mb-1">
        <DistTimeDiffBlock
          :title="item.nextCampStats.legTitle"
          displaytype="report"
          :stats="item.nextCampStats"
        ></DistTimeDiffBlock>
      </div>
    </div>
  </div>
</template>
<script>
import DistTimeDiffBlock from "./../infoblocks/DistTimeDiffBlock.vue";

export default {
  name: "subLegstats",

  components: { DistTimeDiffBlock },

  data: () => ({}),
  props: {
    iteminfo: Array
  },
  methods: {}
};
</script>

<style></style>
