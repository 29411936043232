var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ready)?_c('v-card',{staticClass:"pb-1",attrs:{"elevation":"6","x_shaped":"","color":'success',"tile":""}},[(!(_vm.properties.media_urls && _vm.properties.media_urls.length))?_c('div',{staticStyle:{"height":"0","width":"100%","overflow":"visible","text-align":"right","z-index":"100","position":"absolute"}},[_c('v-btn',{staticClass:"ma-0",attrs:{"elevation":"0","icon":"","color":"black"},on:{"click":_vm.clearAllPopups}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1):_vm._e(),(_vm.properties.media_urls && _vm.properties.media_urls.length)?_c('v-btn',{staticClass:"floating-close-btn",attrs:{"elevation":"0","tile":"","small":"","color":"white"},on:{"click":_vm.clearAllPopups}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e(),(_vm.properties.media_urls && _vm.properties.media_urls.length)?_c('PopupGallery',{attrs:{"media_urls":_vm.properties.media_urls,"properties":_vm.properties}}):_vm._e(),(_vm.properties.title)?_c('v-card-title',{staticClass:"pt-1 mb-1 pb-1"},[_vm._v(" "+_vm._s(_vm.properties.title)+" "),(_vm.properties.id || _vm.properties.id == 0)?_c('font',[_vm._v("("+_vm._s(_vm.properties.id)+")")]):_vm._e()],1):_vm._e(),(!_vm.isCampsite)?[_c('nonCampsitePopup',{attrs:{"layerId":_vm.layerId,"properties":_vm.properties,"functions":_vm.functions}})]:_vm._e(),(_vm.isCampsite)?[(_vm.properties.campsite_closed)?_c('v-card-subtitle',{staticClass:"pb-1"},[_vm._v(" (Camp Site Closed) ")]):_vm._e(),_c('v-card-text',{staticClass:"pb-2"},[_c('CampSiteDetails',{attrs:{"properties":_vm.properties,"whereShow":"popup"}})],1),_c('v-card-text',{staticClass:"pt-0 pb-1"},[(_vm.properties && _vm.properties.nextCampStats)?_c('PopupDistToBlock',{attrs:{"properties":_vm.properties}}):_vm._e()],1),(_vm.properties.pointType)?_c('v-card-text',{staticClass:"py-1"},[(
          !_vm.properties.campsite_closed &&
            _vm.properties.pointType != 'finish' &&
            _vm.properties.pointType != 'start'
        )?_c('v-btn',{staticClass:"checkbox-btn",class:{
          'checkbox-btn--checked': _vm.skipCampById.includes(_vm.properties.id),
          'checkbox-btn--unchecked': !_vm.skipCampById.includes(_vm.properties.id)
        },attrs:{"elevation":"0","x-small":""},on:{"click":function($event){return _vm.functions.handleSkipCheckboxChangePopup(_vm.properties.id)}}},[(_vm.skipCampById.includes(_vm.properties.id))?_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-checkbox-marked")]):_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-checkbox-blank-outline")]),_vm._v(" Skip This Camp ")],1):_vm._e()],1):_vm._e(),_c('mediaButtons',{attrs:{"acfMedia":_vm.properties.acfMedia,"media_urls":_vm.properties.media_urls,"properties":_vm.properties}})]:_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }