<template>
  <div>
    <div
      v-if="
        properties.nextCampStats &&
          properties.legStats &&
          properties.nextCampStats.distance != properties.legStats.distance
      "
      v-show="
        properties.legStats &&
          properties.pointType != 'finish' &&
          properties.legStats.distance
      "
    >
      <DistTimeDiffBlock
        :title="`Next Leg`"
        displaytype="infowindow"
        :stats="properties.legStats"
      ></DistTimeDiffBlock>
    </div>

    <div
      v-if="
        properties.nextCampStats &&
          properties.nextCampStats.distance &&
          properties.pointType != 'finish'
      "
      v-show="properties.nextCampStats && properties.pointType != 'finish'"
    >
      <DistTimeDiffBlock
        title="To Next Campsite"
        displaytype="infowindow"
        :stats="properties.nextCampStats"
      ></DistTimeDiffBlock>
    </div>

    <div
      v-if="
        properties.lastCampStats &&
          properties.lastCampStats.distance &&
          properties.pointType != 'start'
      "
    >
      <DistTimeDiffBlock
        :showFrom="true"
        title="To Previous Campsite"
        displaytype="infowindow"
        :stats="properties.lastCampStats"
      ></DistTimeDiffBlock>
    </div>
  </div>
</template>

<script>
//import { eventBus } from "../../main";

import DistTimeDiffBlock from "../../../infoblocks/DistTimeDiffBlock.vue";

export default {
  name: "DistToBlock",
  components: { DistTimeDiffBlock },

  data: () => ({}),

  props: {
    properties: Object
  },

  computed: {}
};
</script>
<style scoped></style>
