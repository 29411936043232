<template>
  <div class="no-print">
    <v-btn
      v-if="listmenu"
      class="mr-1"
      @click="downloadGpx()"
      color="blue"
      :title="`Download Section Gpx File - ${legTitleHuman}`"
      elevation="2"
      outlined
    >
      <v-icon>{{ icon }}</v-icon>

      {{ title ? title : legTitle }}
    </v-btn>

    <v-btn
      v-else
      color="blue"
      elevation="0"
      :icon="!report"
      outlined
      title="Download Section Gpx File"
      @click="downloadGpx()"
    >
      <font v-show="report">
        {{ title }}
      </font>
      <v-icon>{{ icon }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
//import { eventBus } from "../../main";
import { DOMImplementation } from "@xmldom/xmldom";
import GeoJsonToGpx from "@dwayneparton/geojson-to-gpx";
const implementation = new DOMImplementation();

if (implementation) {
  //do nothingl
}

export default {
  name: "geoJsondownloadGpxButton",
  components: {},

  props: {
    geojson: Object,
    title: String,
    icon: String,
    legTitle: String,
    legTitleHuman: String,
    report: Boolean,
    listmenu: Boolean
  },
  methods: {
    downloadGpx() {
      const options = {
        metadata: {
          name: "name",
          author: {
            name: "name",
            link: {
              href: "https://www.temp.com"
            }
          }
        }
      };

      // Will convert geojson into xml document
      const gpx = GeoJsonToGpx(this.geojson, options);

      // convert document to string or post process it
      const gpxString = new XMLSerializer().serializeToString(gpx);

      // @see https://stackoverflow.com/questions/10654971/create-text-file-from-string-using-js-and-html5
      const link = document.createElement("a");
      link.download = `${this.legTitle}.gpx`;
      const blob = new Blob([gpxString], { type: "text/xml" });
      link.href = window.URL.createObjectURL(blob);
      link.click();
    }
  },
  computed: {}
};
</script>
