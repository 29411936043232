<template>
  <div>
    <template v-for="(row, index) in values">
      <div :key="`acfNoteBlock_${index}`">
        <v-card-text>
          <h3>
            <b>{{ row.label }}</b>
          </h3>

          {{ row.value }}
        </v-card-text>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "acfNoteBlock",
  components: {},

  props: {
    properties: Object,
    section: String,
    valuesToShow: Array
  },
  methods: {},
  computed: {
    values() {
      let values = [];
      if (
        this.valuesToShow?.length &&
        this.section &&
        this.properties &&
        this.properties[this.section]
      ) {
        let dataset = this.properties[this.section];

        this.valuesToShow.forEach(acfKey => {
          if (dataset[acfKey]) {
            let row = dataset[acfKey];

            let { label, value, type } = row;

            if (type === "textarea") {
              values.push({ acfKey, label, value });
            }
          }
        });
      }

      return values;
    }
  }
};
</script>
