<template>
  <div v-if="ready">
    lajsdfljasdlkfj asjdklf jaslkd fj 'sadfkl ajsdlkfj asl;kd fj
    aksldjfl;asjdfl;ajsd f'
  </div>
</template>

<script>
//import { eventBus } from "../../main";

import Vue from "vue";

import { eventBus } from "../../../../main";

export default Vue.extend({
  components: {},
  data: () => ({
    ready: false,
    mobileShowLegend: true,
    currentThematics: {}
  }),

  props: {
    functions: Object,
    legendData: Object,
    map: Object,
    appSettings: Object,
    maplayerdata: Object,
    vuetifyCopy: {
      required: true,
      type: Object
    }
  },

  computed: {
    layersObjects() {
      return this.maplayerdata.layers;
    },
    layersArray() {
      return Object.values(this.maplayerdata.layers);
    },
    thematicsObject() {
      return Object.values(this.maplayerdata.thematics);
    }
  },
  mounted() {
    console.log("planner mounted!!!");
    this.$vuetify = this.vuetifyCopy;

    this.ready = true;

    eventBus.$on("mapLegend_updateLegendData", this.updateValue);

    eventBus.$on("mapLegend_setMobileOpen", value => {
      this.mobileShowLegend = value ? true : false;
    });
  },

  methods: {
    updateValue(value) {
      this.currentThematics = value;
    }
  }
});
</script>

<style>
.legendHeading {
  width: 105px;
  height: 46px;
  font-size: 14px;
}
.legendItem {
  font-size: 12px;
}
</style>
