<template>
  <div
    class="mapWrapper"
    :class="{
      drawerOpen: drawer
    }"
  >
    <mapbox
      :access-token="appSettings.map.accessToken"
      :map-options="{ ...options, ...appSettings.map.options }"
      @map-load="loaded"
      @map-init="initialized"
      :nav-control="{
        show: false,
        position: 'top-left'
      }"
      :scale-control="{
        show: false,
        position: 'top-left'
      }"
      :fullscreen-control="{
        show: false,
        position: 'top-left'
      }"
    />
  </div>
</template>

<script>
//cluster ajsdflj https://docs.mapbox.com/mapbox-gl-js/example/cluster-html/

import { mapState } from "vuex";
import { eventBus } from "../../main";

import Mapbox from "mapbox-gl-vue";

import controlsLayerSearchMixin from "./mixins/controls-layer-search-mixin.js";

import pathEventsMixin from "./mixins/path-events-mixin.js";

import layerSelectorMixin from "./modules/layerselector/layer-selector-mixin.js";
import pathMixin from "./modules/path/path-mixin.js";
import plannerControlsMixin from "./modules/planner/planner-controls-mixin.js";
import campInfowindowEventsMixin from "./modules/popup/campsite-popup-events-mixin.js";

import campTooltipEventsMixin from "./modules/tooltips/campsite-tooltip-events-mixin.js";

export default {
  components: { Mapbox },
  mixins: [
    campTooltipEventsMixin,
    layerSelectorMixin,
    pathMixin,
    plannerControlsMixin,

    controlsLayerSearchMixin,

    campInfowindowEventsMixin,

    pathEventsMixin
  ],
  name: "Map",
  computed: {
    ...mapState({
      isDevEnviroment: state => state.navigation.isDevEnviroment,

      //global:
      appSettings: state => state.global.appSettings,

      layers: state => state.filters.layers
    })
  },

  props: {
    drawer: Boolean
  },
  data: () => ({
    flyToTimeout: null,
    flyToTimeoutBackup: null,
    currentZoom: 0,
    lastMarkerSize: null,
    toolTipAct: null,
    useCluster: false,
    useCircles: true,
    popups: [],
    map: null,
    options: {
      container: "mapContainer",
      projection: "mercator" //equalEarth, globe, naturalEarth, mercator
    }
  }),
  mounted() {
    window.onresize = () => {
      this.resizeMap();
    };

    window.onresize = () => {
      this.resizeMap();
    };
  },
  created() {
    eventBus.$on("nav_exitFullScreen", () => {
      this.exitFullScreen();
    });

    eventBus.$on("nav_map_reset", () => {
      this.resetMap();
      this.resetUi();
    });
  },

  watch: {
    drawer: function() {
      this.resizeMap();
    }
  },
  methods: {
    resizeMap() {
      this.map.resize();
      setTimeout(() => {
        this.map.resize();
      }, 250);

      setTimeout(() => {
        this.map.resize();
      }, 500);
    },
    queryStringLoad(decodeQuerySetup, pitch, center, bearing, zoom) {
      zoom = decodeQuerySetup.zoom;
      center = [decodeQuerySetup.centerLng, decodeQuerySetup.centerLat];

      let infoWindowCenter;
      if (
        decodeQuerySetup.infoWindowTargetLat ||
        decodeQuerySetup.infoWindowTargetLng
      ) {
        center = [
          decodeQuerySetup.infoWindowTargetLng,
          decodeQuerySetup.infoWindowTargetLat
        ];

        infoWindowCenter = {
          latitude: decodeQuerySetup.infoWindowTargetLat,
          lat: decodeQuerySetup.infoWindowTargetLat,
          longitude: decodeQuerySetup.infoWindowTargetLng,
          lng: decodeQuerySetup.infoWindowTargetLng
        };
      }
      this.map.flyTo({ pitch, center, bearing, zoom, xduration: 1000 });

      setTimeout(() => {
        setTimeout(() => {
          try {
            if (
              decodeQuerySetup.infoWindowTargetLat ||
              decodeQuerySetup.infoWindowTargetLng
            ) {
              this.flyToPoint(infoWindowCenter, true, zoom);
              this.$store.dispatch(
                "navUpdate_infoWindowTarget",
                infoWindowCenter
              );
            }
          } catch (error) {
            //do nothing;
          }
        }, 1000);
      }, 1000);

      setTimeout(() => {
        this.startTrackingEvents();
      }, 2500);
    },
    resetUi() {
      //this.updateThemeAndLayerfromOrderIndex(0, 0);
      eventBus.$emit("mapLegend_setMobileOpen", true);
      eventBus.$emit("mapFilter_setMobileOpen", false);
      eventBus.$emit("tableList_setMobileOpen", false);
      eventBus.$emit("tableList_setDesktopOpen", true);

      setTimeout(() => {
        this.$store.dispatch("navUpdate_clearQueryStringPath");
      }, 3000);
      setTimeout(() => {
        this.$store.dispatch("navUpdate_clearQueryStringPath");
      }, 4000);
    },
    resetMap(firstLoad) {
      let {
        pitch,
        center,
        bearing,
        zoom,
        zoomMob,
        zoomTab,
        padding
      } = this.appSettings.map.defaultSetup;

      if (this.appSettings.map.bounds) {
        this.map.setMaxBounds(this.appSettings.map.bounds);
        this.map.setPadding(padding);
      }

      let decodeQuerySetup = this.getDecodedQueryString();
      if (firstLoad && decodeQuerySetup) {
        this.queryStringLoad(decodeQuerySetup, pitch, center, bearing, zoom);
      } else {
        if (this.$vuetify.breakpoint.mobile) {
          if (zoomMob) {
            zoom = zoomMob;
          }
        } else if (this.$vuetify.breakpoint.smAndDown) {
          if (zoomTab) {
            zoom = zoomTab;
          }
        }

        if (firstLoad) {
          //do nothing;
        } else {
          this.clearAllPopups("d");
        }

        if (this.appSettings.map.flyToBounds) {
          let options = { bearing, pitch };
          this.map.fitBounds(this.appSettings.map.flyToBounds, options);
        } else if (this.appSettings.map.defaultSetup) {
          this.map.flyTo({ pitch, center, bearing, zoom });
        }
        setTimeout(() => {
          this.startTrackingEvents();
        }, 2500);
      }
    },

    updateMobileFilterView(value) {
      this.$store.dispatch("navUpdate_updateMobileFilterShow", value);
    },
    updateMobileSuburbView(value) {
      this.$store.dispatch("navUpdate_updateMobileSuburbShow", value);
    },

    initialized(/*map*/) {},

    async loaded(map) {
      //window.clearAllPopups = this.clearAllPopups.bind(this);
      map._isVue = true;
      this.map = map;
      window.thismap = map;

      if (this.appSettings.map.disableRotationAndBearing) {
        // disable map rotation using right click + drag
        this.map.dragRotate.disable();
        // disable map rotation using touch rotation gesture
        this.map.touchZoomRotate.disableRotation();
      }

      this.resetMap(true);

      try {
        this.currentZoom = map.getZoom();
        this.currentZoom = Math.round(this.currentZoom * 100) / 100;
      } catch (error) {
        //do nothing;
      }

      map.on("moveend", () => {
        try {
          this.currentZoom = map.getZoom();

          this.currentZoom = Math.round(this.currentZoom * 100) / 100;

          this.$store.dispatch("navUpdate_zoom", this.currentZoom);
        } catch (error) {
          //do nothing;
        }

        try {
          this.$store.dispatch("navUpdate_center", this.map.getCenter());
        } catch (error) {
          //do nothing;
        }
      });

      this.initializePath();
      this.initializeInfoWindowEvents();
      this.initializeTooltipsEvents();
      this.addControlsAndAddressSearch();
      this.map.addSource("mapbox-dem", {
        type: "raster-dem",
        url: "mapbox://mapbox.mapbox-terrain-dem-v1",
        tileSize: 512,
        maxzoom: 14
      });
      this.map.setTerrain({ source: "mapbox-dem", exaggeration: 1.5 });
    }
  }
};
</script>

<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.container > div > .mapWrapperWrapper {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100vw;
  max-width: 100vw !important;
  border: none;
}

#mapContainer {
  height: 100%;
  width: 100vw;
  padding: 0;
  margin: 0;

  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1) max-width,
    0.25s cubic-bezier(0.4, 0, 0.2, 1) width;
}

.isSmallComputer .drawerOpen #mapContainer {
  width: calc(100vw - 450px);
}

#currentZoomDev {
  height: 28px;
  width: 54px;
  overflow: visible;
  z-index: 2;
  top: 183px;
  left: 10px;
  position: absolute;
  background: white;
  border: 2px solid #ff000052;
  border-radius: 5px;
  text-align: center;
  font-size: 17px;
}
</style>
