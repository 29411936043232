var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.properties.nextCampStats &&
        _vm.properties.legStats &&
        _vm.properties.nextCampStats.distance != _vm.properties.legStats.distance
    )?_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.properties.legStats &&
        _vm.properties.pointType != 'finish' &&
        _vm.properties.legStats.distance
    ),expression:"\n      properties.legStats &&\n        properties.pointType != 'finish' &&\n        properties.legStats.distance\n    "}]},[_c('DistTimeDiffBlock',{attrs:{"title":"Next Leg","displaytype":"infowindow","stats":_vm.properties.legStats}})],1):_vm._e(),(
      _vm.properties.nextCampStats &&
        _vm.properties.nextCampStats.distance &&
        _vm.properties.pointType != 'finish'
    )?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.properties.nextCampStats && _vm.properties.pointType != 'finish'),expression:"properties.nextCampStats && properties.pointType != 'finish'"}]},[_c('DistTimeDiffBlock',{attrs:{"title":"To Next Campsite","displaytype":"infowindow","stats":_vm.properties.nextCampStats}})],1):_vm._e(),(
      _vm.properties.lastCampStats &&
        _vm.properties.lastCampStats.distance &&
        _vm.properties.pointType != 'start'
    )?_c('div',[_c('DistTimeDiffBlock',{attrs:{"showFrom":true,"title":"To Previous Campsite","displaytype":"infowindow","stats":_vm.properties.lastCampStats}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }