<template>
  <div>
    <v-carousel
      height="130px"
      hide-delimiters
      :show-arrows="media_urls.length > 1"
    >
      <v-carousel-item
        v-for="image in media_urls"
        :key="image.id"
        :src="image.popup_thumb"
      >
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
//import { eventBus } from "../../main";

export default {
  name: "popupGallery",
  components: {},

  data: () => ({}),

  props: {
    properties: Object,
    media_urls: Array
  },

  computed: {}
};
</script>
<style scoped>
.peramItem {
  width: 100%;
}
.peramItem i {
  width: 32px;
}
</style>
