<template>
  <v-card class="mb-2 report-row" v-if="campsite">
    <v-card-title>
      <v-icon>mdi-tent</v-icon> Campground: {{ campsite.title }} ({{
        campsite.id
      }})

      <font
        class="pl-1 text-capitalize"
        v-show="campsite.pointType == 'start' || campsite.pointType == 'finish'"
      >
        - {{ campsite.pointType }}
      </font>
    </v-card-title>

    <v-card-text>
      <CampSiteDetails :properties="campsite"></CampSiteDetails>
    </v-card-text>

    <acfNoteBlock
      :properties="campsite"
      section="acfCampsite"
      :valuesToShow="[
        'hh_camp_textarea_campsite_notes',
        'hh_camp_textarea_campsite_warning'
      ]"
    ></acfNoteBlock>
  </v-card>
</template>

<script>
import CampSiteDetails from "./../infoblocks/CampSiteDetails";
import acfNoteBlock from "./../ui/acfNoteBlock";

export default {
  name: "tripCampSite",
  components: { CampSiteDetails, acfNoteBlock },

  props: {
    campsite: Object
  },

  computed: {},
  mounted() {}
};
</script>
