const splitAcf = require("./splitAcf");

const getCampSite = (targetPostId, campsiteData) => {
  try {
    let data = {};
    try {
      data = campsiteData.filter(
        row => row?.properties?.postId == targetPostId
      )[0]?.properties;
    } catch (e) {
      //do nothing;
    }

    let { campSiteID, title, postId, slug } = data;

    return { campSiteID, title, postId, slug };
  } catch (error) {
    //console.log(error);
    return null;
  }
};

const prepProperites = (post, campsites) => {
  //coreThings

  let {
    taxonomyValues,
    postId,
    slug,

    title,
    status,
    type,

    media_urls,
    content,
    acf
  } = post;

  let fromPostId = acf?.hh_section_post_starting_location?.value;
  let toPostId = acf?.hh_section_post_finishing_location?.value;

  let fromPostObj = getCampSite(fromPostId, campsites);
  let toPostIdObj = getCampSite(toPostId, campsites);

  if (!fromPostObj || !fromPostObj.postId) {
    fromPostObj = null;
    fromPostId = null;
  }
  if (!toPostIdObj || !toPostIdObj.postId) {
    toPostIdObj = null;
    toPostId = null;
  }

  return {
    name: title,
    description: content,
    startId: fromPostObj?.campSiteID,
    endId: toPostIdObj?.campSiteID,
    postId,
    details: {
      title,
      slug,
      fromPostId,
      toPostId,
      fromPostObj,
      toPostIdObj,

      status,
      type,
      content,
      media_urls,
      ...splitAcf.split(acf),
      taxonomyValues
    }
  };
};

const process = (posts, campsites) => {
  let sections = [];

  posts.forEach(post => {
    let section = prepProperites(post, campsites);

    sections.push(section);
  });

  return sections;
};

module.exports.process = process;
