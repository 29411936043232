const targets = [
  {
    postId: "point",
    taxonomies: [],
    campsite: true,
    geoData: true,
    geomType: "point",
    geoLinkedData: false,
    nonGeoData: false
  },
  {
    postId: "section",
    taxonomies: [],
    geoData: false,
    geoLinkedData: true,
    nonGeoData: false
  },
  {
    postId: "posts",
    taxonomies: ["category"],
    geoData: false,
    geoLinkedData: false,
    nonGeoData: true
  },

  {
    postId: "pages",
    taxonomies: [],
    geoData: false,
    geoLinkedData: false,
    nonGeoData: true
  }
];

const wpUrl = "https://omwptest.onemap.com.au";

module.exports = {
  targets,
  wpUrl
};
