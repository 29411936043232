<template>
  <div class="d-flex  flex-row   flex-wrap ">
    <template v-for="(data, acfkey) in acfTrack">
      <div :key="`acfTrack${acfkey}`">
        <acfValue
          :acfkey="acfkey"
          :data="data"
          :hideTextBlocks="hideTextBlocks"
        ></acfValue>
      </div>
    </template>
  </div>
</template>

<script>
//import { eventBus } from "../../main";

import acfValue from "./../ui/acfValue.vue";

export default {
  name: "TrackDetails",
  components: { acfValue },

  data: () => ({}),

  props: {
    properties: Object,
    hideTextBlocks: Boolean
  },

  computed: {
    acfTrack() {
      return this?.properties?.acfTrack;
    }
  }
};
</script>
<style scoped>
.peramItem {
  width: 100%;
  width: auto;
  padding-right: 10px;
}
.peramItem i {
  width: 32px;
}
</style>
