<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Open Trip Report
        </v-btn>
      </template>
      <div>
        <v-toolbar dark flat color="primary" class="no-print">
          <v-toolbar-title>Trip Report</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn tile dark @click="printReport" class="mr-2">
            print
            <v-icon>mdi-printer</v-icon>
          </v-btn>
          <v-btn tile dark @click="dialog = false" class="mr-2">
            close
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-items> </v-toolbar-items>
        </v-toolbar>
        <div class="reportCardWrapper">
          <v-card flat>
            <v-card-title>
              <span class="headline">Trip Report</span>
            </v-card-title>
            <v-card-text>
              <div class="report-content">
                <tripEntireJourneyStats></tripEntireJourneyStats>

                <template v-for="item in sectionReport">
                  <tripReportItem
                    :legItem="item"
                    :key="'reportListItem_' + item.journeyDay"
                  />
                </template>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </v-dialog>
  </v-row>
</template>

<script>
import tripReportItem from "./tripReportItem.vue";
import tripEntireJourneyStats from "./tripEntireJourneyStats.vue";
import { eventBus } from "../../main";

import { mapState } from "vuex";

export default {
  name: "tripReport",

  components: { tripReportItem, tripEntireJourneyStats },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      //elevationData: state => state.trail.elevationData,
      filteredCampsitesGeoJson: state => state.trail.filteredCampsitesGeoJson,
      //filteredTrackLineString: state => state.trail.filteredTrackLineString,
      filteredStats: state => state.trail.filteredStats
    }),
    filteredCampsitesArray() {
      let array = this.filteredCampsitesGeoJson.features.map(row => {
        return row.properties;
      });
      return array;
    },
    sectionReport() {
      console.log("sectionReport - should happen in state, not a controller ");

      let sectionLookup = {};

      this.filteredCampsitesArray.forEach(row => {
        let { journeyDay } = row;
        if (!sectionLookup[journeyDay]) {
          sectionLookup[journeyDay] = {
            journeyDay,
            start: {},
            stops: [],
            stopsTitles: []
          };
        }

        sectionLookup[journeyDay].stops.push(row);
        sectionLookup[journeyDay].stopsTitles.push(row.title);
      });

      let legArray = Object.values(sectionLookup);

      legArray.forEach(row => {
        let { journeyDay } = row;
        if (journeyDay !== 1 && sectionLookup[journeyDay - 1]) {
          let prevDay = sectionLookup[journeyDay - 1];

          let {
            0: fromSite,
            [prevDay.stops.length - 1]: toSite
          } = prevDay.stops;

          if (fromSite) {
            //do nothing;
          }

          row.stops.unshift(toSite);
          row.stopsTitles.unshift(toSite.title);
        }
      });

      let output = [];

      let index = 0;
      legArray.forEach(row => {
        let { journeyDay, stops } = row;

        let { 0: fromSite, [stops.length - 1]: toSite } = stops;
        let fromTitle = fromSite.title;
        let toTitle = toSite.title;
        let legStats = fromSite.legStats;

        let fromToTitle = `${fromTitle} - ${toTitle} (${legStats?.distance}km)`;
        let subLegs = [];

        let inbetweenStops = [];

        if (stops.length > 2) {
          stops.forEach((row, index) => {
            let { nextCampStats, title } = row;
            if (stops[index + 1]) {
              let nextRow = stops[index + 1];
              subLegs.push({
                toTitle: title,
                fromTitle: nextRow.title,
                nextCampStats,
                distance: nextCampStats.distance,
                title: `${title} - ${nextRow.title} (${nextCampStats?.distance}km)`
              });
            }

            if (stops[index + 1] && index != 0) {
              inbetweenStops.push(row);
            }
          });
        }

        if (!legStats.acfTrackClone) {
          legStats.acfTrackClone = JSON.parse(
            JSON.stringify(legStats.acfTrack)
          );
        }
        legStats.acfTrack = JSON.parse(JSON.stringify(legStats.acfTrackClone));

        if (subLegs?.length) {
          try {
            legStats.subLegs = subLegs;

            //legStats.acfTrack.hh_track_numb_time_to_next_camp;
            //legStats.acfTrack.hh_track_select_difficulty;
            //legStats.acfTrack.hh_track_multiselect_track_type;

            let hh_track_numb_time_to_next_camp = 0;
            let hh_track_select_difficulty = [];
            let hh_track_multiselect_track_type = [];

            subLegs.forEach(row => {
              hh_track_numb_time_to_next_camp += row?.nextCampStats?.acfTrack
                ?.hh_track_numb_time_to_next_camp?.value
                ? row?.nextCampStats?.acfTrack?.hh_track_numb_time_to_next_camp
                    ?.value
                : 0;
              hh_track_select_difficulty.push(
                row?.nextCampStats?.acfTrack?.hh_track_select_difficulty?.value
                  ? row?.nextCampStats?.acfTrack?.hh_track_select_difficulty
                      ?.value
                  : "Easy"
              );
              hh_track_multiselect_track_type = [
                ...hh_track_multiselect_track_type,
                ...(row?.nextCampStats?.acfTrack
                  ?.hh_track_multiselect_track_type?.value
                  ? row?.nextCampStats?.acfTrack
                      ?.hh_track_multiselect_track_type?.value
                  : [])
              ];
            });

            if (hh_track_numb_time_to_next_camp) {
              legStats.acfTrack.hh_track_numb_time_to_next_camp.value = hh_track_numb_time_to_next_camp;
            }
            if (hh_track_select_difficulty.length) {
              if (hh_track_select_difficulty.includes("Hard")) {
                hh_track_select_difficulty = "Hard";
              } else if (hh_track_select_difficulty.includes("Medium")) {
                hh_track_select_difficulty = "Medium";
              } else if (hh_track_select_difficulty.includes("Easy")) {
                hh_track_select_difficulty = "Easy";
              } else {
                hh_track_select_difficulty = null;
              }

              if (hh_track_select_difficulty) {
                legStats.acfTrack.hh_track_select_difficulty.value = hh_track_select_difficulty;
              }
            }

            if (hh_track_multiselect_track_type.length) {
              legStats.acfTrack.hh_track_multiselect_track_type.value = [
                ...new Set(hh_track_multiselect_track_type)
              ];
            }

            console.log(
              legStats.acfTrack.hh_track_multiselect_track_type.value
            );
          } catch (error) {
            //do nothing;
          }
        }
        output.push({
          journeyDay,
          fromSite,
          toSite,
          legStats,
          fromToTitle,
          subLegs,
          inbetweenStops,
          firstItem: index === 0,
          lastItem: index === legArray.length - 1
        });

        index++;
      });

      return output;
    }
  },
  methods: {
    printReport() {
      window.print();
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  created() {
    eventBus.$on("loadTripReportDialog", () => {
      this.dialog = true;
    });
  }
};
</script>
<style>
div.reportCardWrapper {
  height: calc(100vh - 64px);
  max-height: calc(100vh - 64px);
  overflow-y: scroll;
  background-color: white;
}

div.reportCardWrapper > div.v-card {
  border-radius: 0 !important;
  box-shadow: none !important;
}

@media print {
  html,
  body,
  body div.v-application,
  body div.v-application div.reportCardWrapper {
    height: auto !important;
    overflow: visible !important;
    overflow-y: visible !important;
    display: unset !important;
    position: unset !important;
  }

  html body div.v-application div.v-application--wrap {
    display: none;
    visibility: hidden;
  }

  body {
    margin: 0;
    padding: 0;
    color: #000; /* Ensure text is black for print */
  }

  /* Hide elements that are not needed in print */
  .no-print {
    display: none !important;
  }

  div.v-dialog__content.v-dialog__content--active {
    position: static !important; /* Override position to ensure proper layout */
    width: 100% !important; /* Full width for printing */
    height: auto !important; /* Let content dictate height */
    overflow: visible !important; /* Allow content to flow */
  }

  /* Style for the dialog */
  .v-dialog {
    position: static !important; /* Override position to ensure proper layout */
    width: 100% !important; /* Full width for printing */
    height: auto !important; /* Let content dictate height */
    overflow: visible !important; /* Allow content to flow */
  }

  /* Ensure content is visible */

  .v-dialog,
  .v-dialog .v-card,
  .v-dialog .v-sheet {
    box-shadow: none !important; /* Remove shadow for print */
    border: none !important; /* Remove border for print */
  }

  .report-row {
    page-break-inside: avoid;
  }
  /* Page breaks between sections */
  .page-break {
    page-break-after: always; /* Force a page break after this element */
  }

  /* Custom styles for report content */
  .report-content {
    font-size: 12pt; /* Adjust font size for print */
  }

  /* Additional styles as needed */
}

/*
@media print {
  .v-dialog__content.v-dialog__content--active {
    align-items: start;
    display: unset;
    height: auto;
    justify-content: unset;
    left: unset;
    pointer-events: unset;
    position: unset;
    top: unset;
    transition: unset;
    width: unset;
    z-index: unset;
  }

  .v-dialog__content.v-dialog__content--active
    .v-dialog.v-dialog--active.v-dialog--fullscreen {
    transform-origin: unset;
    overflow: visible;
  }

  .reportAct .v-sheet.v-toolbar,
  .reportAct .v-card.v-sheet {
    box-shadow: none !important;
    border: 1px solid black;
  }

  center.main {
    text-align: unset;
  }
}
  */
</style>
