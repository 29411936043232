const splitAcf = require("./splitAcf");

const prepProperites = (post, target) => {
  //coreThings

  if (target) {
    //do nothing;
  }

  let {
    taxonomyValues,
    postId,
    slug,
    longitude,
    latitude,
    title,
    status,
    type,
    media_urls,
    content,
    acf
  } = post;

  var output = {
    title,
    postId,
    slug,
    longitude,
    latitude,
    status,
    type,
    content,
    media_urls,
    ...splitAcf.split(acf),
    taxonomyValues
  };

  try {
    if (output.campSiteID) {
      output.titleRaw = output.title;
      output.title = output.title.split("_")[1];
    }
  } catch (error) {
    //Do nothing
  }

  return output;
};

const convertPostsToGeoJSON = (posts, target) => {
  let features = [];

  posts.forEach(post => {
    let isValid = false;
    let geometry = null;
    if (
      target.geomType === "point" &&
      post.hh_osmlonglat_location &&
      post.hh_osmlonglat_location[0] &&
      post.hh_osmlonglat_location[1] &&
      !isNaN(post.hh_osmlonglat_location[0]) &&
      !isNaN(post.hh_osmlonglat_location[1])
    ) {
      try {
        let lng, lat;
        let [lngTemp, latTemp] = post.hh_osmlonglat_location;

        lng = parseFloat(lngTemp);
        lat = parseFloat(latTemp);

        geometry = {
          type: "Point",
          coordinates: [lng, lat]
        };

        isValid = true;

        post.longitude = lat;
        post.latitude = lng;
      } catch (error) {
        //Do nothing
      }
    }

    if (geometry && isValid) {
      let properties = prepProperites(post, target);
      let { postId } = properties;

      let feature = {
        type: "Feature",
        geometry,
        properties,
        postId: postId
      };
      features.push(feature);
    }
  });

  try {
    features.sort((a, b) =>
      a?.properties?.titleRaw.localeCompare(b?.properties?.titleRaw)
    );
  } catch (error) {
    //do nothing;
  }

  return features;
};

module.exports.convertPostsToGeoJSON = convertPostsToGeoJSON;
