<template>
  <div v-if="show">
    <div class="pr-1">
      <b>{{ label }}: </b>{{ value }}

      <a
        v-if="extra && extra.type == 'link'"
        target="_blank"
        :href="extra.url"
        class="acfLink mr-1"
      >
        {{ extra.title }}</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "acfValue",
  components: {},

  props: {
    properties: Object,

    acfkey: String,
    data: Object,
    hideTextBlocks: Boolean
  },
  methods: {},
  computed: {
    acfType() {
      return this?.data?.type;
    },

    show() {
      let show = true;

      if (
        this.acfType == "textarea" &&
        this.hideTextBlocks &&
        this.hideTextBlocks === true
      ) {
        show = false;
      }

      return show;
    },
    value() {
      let value = this?.data?.value;

      try {
        if (["multiselect"].includes(this.acfType)) {
          value = value.join(", ");
        }
      } catch (error) {
        //do nothing;
      }

      try {
        value = value.trim();
      } catch (error) {
        //do nothing;
      }

      return value;
    },
    extra() {
      let hasExtra = false;
      let output = null;
      if (this.acfkey == "hh_am_checkbox_camp_fees") {
        if (
          this?.properties?.acfCampsite?.hh_camp_link_campsite_link_booking
            ?.value?.url
        ) {
          let {
            url,
            title
          } = this?.properties?.acfCampsite?.hh_camp_link_campsite_link_booking?.value;
          title = "Bookings";
          hasExtra = true;
          output = { url, title, type: "link" };
        }
      }
      if (!hasExtra) {
        return false;
      } else {
        return output;
      }
    },
    label() {
      return this?.data?.label;
    }
  }
};
</script>
<style>
.v-application a.acfLink {
  color: #2196f3;
  text-decoration: underline;
}
</style>
