<template>
  <v-card class="mb-2 report-row">
    <v-card-title>
      Journeys Details - from
      {{ filteredStats.fromTitle }}
      to
      {{ filteredStats.toTitle }}
    </v-card-title>

    <v-card-text v-if="filteredStats" class="mb-0 py-0">
      <journeyStats :iteminfo="filteredStats"></journeyStats>
    </v-card-text>

    <v-card-text class="no-print">
      <div class="d-flex flex-row flex-wrap no-print mb-2 ">
        <googleDirectionsButton
          class="mr-4 mb-2 "
          icon="mdi-google-maps"
          title="Directions To Start"
          tooltiptitle="Directions To Start Using Google/Apple Maps"
          :report="true"
          :geom="filteredStats.fromGeom"
        ></googleDirectionsButton>

        <googleDirectionsButton
          class="mr-4 mb-2 "
          icon="mdi-google-maps"
          title="Directions To Finish"
          tooltiptitle="Directions To Finish Using Google/Apple Maps"
          :report="true"
          :geom="filteredStats.toGeom"
        ></googleDirectionsButton>

        <downloadGpxButton
          class="mr-4 mb-2 "
          icon="mdi-download-box"
          title="Download Journey GPX"
          :report="true"
          :geojson="filteredTrackLineString"
          :legTitle="filteredStats.journeyIdDlFile"
        ></downloadGpxButton>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import downloadGpxButton from "./../ui/downloadGpxButton.vue";
import googleDirectionsButton from "./../ui/googleDirectionsButton.vue";
import journeyStats from "./../infoblocks/journeyStats";

export default {
  name: "tripEntireJourneyStats",
  components: { downloadGpxButton, journeyStats, googleDirectionsButton },
  computed: {
    ...mapState({
      filteredCampsitesGeoJson: state => state.trail.filteredCampsitesGeoJson,
      filteredTrackLineString: state => state.trail.filteredTrackLineString,
      filteredStats: state => state.trail.filteredStats
    })
  },
  props: {
    campsite: Object
  },

  mounted() {}
};
</script>
