import { render, staticRenderFns } from "./SideBarLegStats.vue?vue&type=template&id=a7d34028&"
import script from "./SideBarLegStats.vue?vue&type=script&lang=js&"
export * from "./SideBarLegStats.vue?vue&type=script&lang=js&"
import style0 from "./SideBarLegStats.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports