const cleanup = (input, targets) => {
  let { post, taxonomy, settings } = input;

  if (targets) {
    //do nothing;
  }

  if (taxonomy) {
    //do nothing;
  }

  for (const [key, value] of Object.entries(post)) {
    if (key) {
      //do nothing;
    }

    value.forEach(post => {
      post["taxonomyValues"] = {};

      try {
        delete post["_links"];
        delete post["class_list"];
      } catch (error) {
        //Do nothing
      }

      try {
        delete post["guid"];
        delete post["template"];
      } catch (error) {
        //Do nothing
      }

      try {
        if (post?.title?.rendered) post.title = post.title.rendered;
      } catch (error) {
        //do nothing;
      }
      try {
        if (post?.content?.rendered || post.content.rendered === "")
          post.content = post.content.rendered;
      } catch (error) {
        //do nothing;
      }

      try {
        post.postId = post.id;
        delete post["id"];
      } catch (error) {
        //do nothing;
      }
    });
  }

  let output = { post, taxonomy, settings };

  return output;
};

module.exports.cleanup = cleanup;
