//import _, { initial } from "lodash";

import { mapState } from "vuex";
import { EmptyTargetControl } from "./../../controls/emptyTargetControl.js";
import plannerControlsUi from "./plannerControlsUi.vue";

export default {
  data: () => ({
    //layerState: false
    currentPopup: null
  }),

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings
    })
  },
  watch: {},
  methods: {
    addPlannerControls() {
      console.log("addPlannerControls run");
      var emprtyTargetMenuOptions = {
        persistList: true,
        extraClass: "fakeEmptyMenuClass",
        id: "plannerControlsUiFakeDivTarget"
      };
      const emptyTargetControlMapLegend = new EmptyTargetControl(
        emprtyTargetMenuOptions
      );
      //this.map.addControl(emptyTargetControl, 'top-right');
      this.map.addControl(emptyTargetControlMapLegend, "top-left");

      try {
        new plannerControlsUi({
          propsData: {
            functions: {
              //updateThematics: this.updateThematics,
              //updateLayer: this.updateLayer
            },
            map: this.map,
            vuetifyCopy: this.$vuetify,
            //stateCopy: this.$state,
            appSettings: this.appSettings
          }
        }).$mount("#plannerControlsUiFakeDivTarget");
      } catch (error) {
        //do nothink;
      }
    }
  },
  created() {},
  destroyed() {},
  mounted() {}
};
