<template>
  <div>
    <div v-if="false && iteminfo && iteminfo.fromTitle" class="d-flex flex-row">
      <div>
        {{ iteminfo.fromTitle }}
      </div>
      <div class="px-2">
        -
      </div>
      <div>
        {{ iteminfo.toTitle }}
      </div>
    </div>

    <div class="d-flex flex-row legStats sublegblock">
      <div class="pr-2" v-if="iteminfo.distance">
        <font class="prop">Length: </font>
        <font class="distance">{{ iteminfo.distance }} km </font>
      </div>
      <div class="pr-2" v-if="iteminfo.days">
        <font class="prop">Days: </font>
        <font class="daysNights">{{ iteminfo.days }} </font>
      </div>
      <div class="pr-2" v-if="iteminfo.nights">
        <font class="prop">Nights: </font>
        <font class="daysNights">{{ iteminfo.nights }}</font>
      </div>
    </div>
    <div class="d-flex flex-row legStats sublegblock">
      <div class="pr-2" v-if="iteminfo.average">
        <font class="prop">Average Distance: </font>
        <font class="daysNights">{{ iteminfo.average }}km</font>
      </div>
      <div class="pr-2" v-if="iteminfo.minDist && iteminfo.maxDist">
        (
        <font class="prop">Min: </font>
        <font class="daysNights">{{ iteminfo.minDist }}km </font>

        <font class="prop">Max: </font>
        <font class="daysNights">{{ iteminfo.maxDist }}km</font>
        )
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "journeyStats",

  components: {},

  data: () => ({}),
  props: {
    iteminfo: Object
  },
  methods: {}
};
</script>

<style>
.legStats,
.sublegblock {
  color: black;
}
.legStats .prop {
  font-weight: bold;
}

.legStats .rise {
  color: red;
}

.legStats .descent {
  color: blue;
}
</style>
