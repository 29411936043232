<template>
  <div>
    <div class="d-flex  flex-row   flex-wrap compactList">
      <template v-for="(data, acfkey) in acfAmenities">
        <div :key="`acfAmenities_${acfkey}`" v-show="checkShow(acfkey, data)">
          <acfValue
            :acfkey="acfkey"
            :data="data"
            :properties="properties"
          ></acfValue>
        </div>
      </template>
    </div>

    <div v-if="whereShow === 'popup' && acfCampsite">
      <div
        ref="noteTextContent"
        :class="{
          clampFourLines: !noteIsExpanded,
          campText: true,
          'mt-1': true
        }"
        v-if="acfCampsite.hh_camp_textarea_campsite_notes.value"
      >
        {{ acfCampsite.hh_camp_textarea_campsite_notes.value }}
      </div>

      <button
        v-if="
          acfCampsite.hh_camp_textarea_campsite_notes.value && isOverflowing
        "
        @click="toggleText"
      >
        <b> {{ noteIsExpanded ? "Show Less" : "Show More" }}</b>
      </button>

      <div
        class="mt-1 campText"
        v-if="
          acfCampsite.hh_camp_textarea_campsite_warning &&
            acfCampsite.hh_camp_textarea_campsite_warning.value
        "
      >
        <font v-show="showWarning"
          ><b style="color:red">Notices / Warning: </b>
          {{ acfCampsite.hh_camp_textarea_campsite_warning.value }}
        </font>

        <button @click="showWarning = !showWarning">
          <b style="color:red">
            {{ showWarning ? "Hide " : "** Show Notices / Warning **" }}</b
          >
        </button>
      </div>
    </div>
  </div>
</template>

<script>
//import { eventBus } from "../../main";

import acfValue from "./../ui/acfValue.vue";

export default {
  name: "CampSiteDetails",
  components: { acfValue },

  data: () => ({
    hideIfNoPopup: {
      hh_am_yesno_shelter: true,
      hh_am_checkbox_camp_fees: true
    },

    noteIsExpanded: false,
    showWarning: false,
    isOverflowing: false
  }),

  mounted() {
    this.checkOverflow();
    window.addEventListener("resize", this.checkOverflow); // Re-check overflow on resize
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkOverflow);
  },

  methods: {
    checkOverflow() {
      if (this.whereShow === "popup") {
        if (
          this?.properties?.acfCampsite?.hh_camp_textarea_campsite_notes?.value
        ) {
          const noteTextContent = this.$refs.noteTextContent;
          this.isOverflowing =
            noteTextContent.scrollHeight > noteTextContent.clientHeight;
        }
      }
    },
    toggleText() {
      this.noteIsExpanded = !this.noteIsExpanded;
    },

    checkShow(key, data) {
      let show = true;
      if ((key, data)) {
        //do nothing;
      }

      if (this.whereShow === "popup") {
        //do nothing;
        if (this.hideIfNoPopup[key] && data?.value === "No") {
          show = false;
        }
      }

      return show;
    }
  },
  props: {
    properties: Object,
    whereShow: String
  },

  computed: {
    acfAmenities() {
      return this?.properties?.acfAmenities;
    },
    acfCampsite() {
      if (
        this?.properties?.acfCampsite?.hh_camp_textarea_campsite_notes?.value ||
        this?.properties?.acfCampsite?.hh_camp_textarea_campsite_warning?.value
      ) {
        return this?.properties?.acfCampsite;
      } else {
        return null;
      }
    }
  }
};
</script>
<style scoped>
.peramItem {
  width: 100%;
  width: auto;
  padding-right: 10px;
}
.peramItem i {
  width: 32px;
}

.campText {
  line-height: 1.5em; /* Line height to match */
}

.clampFourLines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4; /* Limits to 4 lines */
  max-height: 6em; /* Adjust as needed based on line-height */
  line-height: 1.5em; /* Line height to match */
}

.compactList {
  line-height: 1.32;
}
</style>
