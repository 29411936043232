const wpPull = require("./modules/wpPull.js");

const _loadWpMapData = async function() {
  let output = null;
  try {
    output = await wpPull.getData();
  } catch (error) {
    console.error("error loading wp data");
  }

  return output;
};

module.exports.loadWpMapData = _loadWpMapData;
