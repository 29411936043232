<template>
  <div class="d-flex flex-row subLegStats sublegblock">
    <div class="pr-2 subheading">
      <b>SublegCampsites:</b>
    </div>
    <div class="pr-2">
      <div v-for="item in iteminfo" :key="item.title">
        Campground: {{ item.title }} ({{ item.id }})
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reportSubLegCampsite",

  components: {},

  data: () => ({}),
  props: {
    iteminfo: Array
  },
  methods: {}
};
</script>

<style></style>
