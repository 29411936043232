<template>
  <div class="no-print">
    <v-btn
      class="multi-line-btn"
      outlined
      :title="tooltiptitle"
      @click="googleDirections()"
    >
      <v-icon class="icon-left">{{ icon }}</v-icon>
      <div class="text-wrapper">
        {{ title }}
      </div>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "googleDirectionsButton",
  components: {},

  props: {
    title: String,
    tooltiptitle: String,
    icon: String,
    geom: Object,
    report: Boolean
  },
  methods: {
    googleDirections() {
      try {
        let [Latitude, Longitude] = this?.geom?.geometry?.coordinates;
        let url = null;
        if (Latitude && Longitude) {
          if (this.isIOS) {
            url = `http://maps.apple.com/?daddr=${Longitude},${Latitude}`;
          } else {
            url = `https://www.google.com/maps/dir/Current+Location/${Longitude},${Latitude}`;
          }

          if (url) {
            window.open(url);
          }
        }
      } catch (error) {
        //do nothing;
        console.error("error running googleDirections");
      }
    }
  },
  computed: {
    isIOS() {
      var userAgent = window.navigator.userAgent;
      let isIpad = false;
      if (
        userAgent.match(/iPad/i) ||
        userAgent.match(/iPhone/i) ||
        userAgent.match(/Macintosh/i)
      ) {
        isIpad = true;
      } else {
        // Anything else
      }

      if (
        this.$browserDetect.isSafari ||
        this.$browserDetect.isIOS ||
        this.$browserDetect.isChromeIOS ||
        isIpad
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
<style>
.multi-line-btn {
  display: flex; /* Flex layout to align icon and text */
  align-items: center; /* Vertically center the content */
  justify-content: flex-start; /* Align icon and text to the left */
  max-width: 200px; /* Set a max width to force text wrapping */
  white-space: normal; /* Allow text to wrap */
  text-align: left; /* Left-align text */
  height: auto !important;
}

.icon-left {
  margin-right: 5px; /* Add spacing between the icon and text */
  margin-left: -5px; /* Add spacing between the icon and text */
}

.text-wrapper {
  display: block; /* Make the text behave like a block element to wrap properly */
  white-space: normal; /* Enable wrapping of the text */
  overflow-wrap: break-word; /* Ensure long words break */
  word-wrap: break-word; /* Older browsers compatibility */
  width: 100px;
}
</style>
